import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../assets/styles/search.scss";
import { SearchProvider } from "../../../contexts/SearchContext";
import { FilterBar } from "./FilterBar";
import SearchBar from "./SearchBar";
import {
  docSortBy,
  staticDateRanges,
  uploadedDocumentStatus,
} from "../../../constants";
import { optionsFromMapperObj } from "./helpers";
import { useReload } from "../../../hooks/useReload";
import { setDocumentSearch } from "../../../store/actions/search";
import { useMessageBoxError } from "../../../hooks/useMessageBox";
import { Results } from "./Results";
import { Divider } from "antd";

const statusOptions = optionsFromMapperObj(uploadedDocumentStatus);
const dateOptions = optionsFromMapperObj(staticDateRanges);
const sortOptions = optionsFromMapperObj(docSortBy);

/**
 * Search page - provides interface for find documents that matches search query
 *
 * @function
 *
 * @returns React JSX
 */
const Search = () => {
  const [entityTypes, setEntityTypes] = useState();
  const [docStatus, setDocStatus] = useState();
  const [dateRange, setDateRange] = useState("all_time");
  const [customDateRange, setCustomDateRange] = useState();
  const [sortBy, setSortBy] = useState("most_relevant");
  const [entityName, setEntityName] = useState("");

  const { analysis, search } = useSelector((state) => state);
  const {
    getSearchRequest: { error, result },
    documents,
  } = search;

  const dispatch = useDispatch();

  useMessageBoxError(error);
  useReload(result, () => dispatch(setDocumentSearch(result.data)));

  const typeOptions = Object.values(analysis.entityProps);

  useEffect(() => {
    setEntityTypes(typeOptions.map((t) => t.value));
    setDocStatus(statusOptions.map((s) => s.value));
  }, []);

  return (
    <SearchProvider
      value={{
        entityTypes,
        setEntityTypes,
        typeOptions,
        docStatus,
        setDocStatus,
        statusOptions,
        dateRange,
        setDateRange,
        dateOptions,
        customDateRange,
        setCustomDateRange,
        sortBy,
        setSortBy,
        sortOptions,
        entityName,
        setEntityName,
      }}
    >
      <div id="documents-search">
        <div className="app-card flex-1">
          <h2 className="app-card-header justify-between">Entity search</h2>
          <div className="app-card-body">
            <section className="mb-20">
              <SearchBar />
            </section>
            {Array.isArray(documents) && (
              <section className="mb-5">
                {!!documents.length && (
                  <h2>
                    {documents.length} result
                    {documents.length > 1 ? "s" : ""}
                  </h2>
                )}
                {!documents.length && <h2>No matching results</h2>}
              </section>
            )}
            <section className="mb-20">
              <FilterBar />
            </section>
            <Divider />
            <section className="">
              <Results documents={documents} entityName={entityName} />
            </section>
          </div>
        </div>
      </div>
    </SearchProvider>
  );
};

export default Search;
