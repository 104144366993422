import axios from "axios";
import { storageIds } from "../../constants";
import api from "../../constants/api";
import { storage } from "../../utils/services/StaticStorage";

const actions = {
  ADD_ENTITY: "ADD_ENTITY",
  ACCEPT_ENTITY: "ACCEPT_ENTITY",
  REJECT_ENTITY: "REJECT_ENTITY",
  REJECT_ENTITY_NO_INTEREST: "REJECT_ENTITY_NO_INTEREST",
  REJECT_ENTITY_NOT_CORRECT: "REJECT_ENTITY_NOT_CORRECT",
  REJECT_BULK_ENTITIES: "REJECT_BULK_ENTITIES",
  ACCEPT_BULK_ENTITIES: "ACCEPT_BULK_ENTITIES",
  DELETE_RELATIONSHIP: "DELETE_RELATIONSHIP",
  ADD_RELATIONSHIP: "ADD_RELATIONSHIP",
  CHANGE_SINGLE_ENTITY: "CHANGE_SINGLE_ENTITY",
  CHANGE_MULTIPLE_ENTITIES: "CHANGE_MULTIPLE_ENTITIES",
  ANALYSIS_DETAILS: "ANALYSIS_DETAILS",
  RESET_ANALYIS_API_STATE: "RESET_ANALYIS_API_STATE",
  GET_DOCUMENT_TLP: "GET_DOCUMENT_TLP",
  ADD_DOCUMENT_TLP: "ADD_DOCUMENT_TLP",
  DELETE_DOCUMENT_TLP: "DELETE_DOCUMENT_TLP",
};

export default actions;

let cancelTokenSource = null;

export const addEntityRequest = (documentId, data, reqId) => ({
  type: actions.ADD_ENTITY,
  payload: axios.post(
    `${api.DOCUMENT_ENTITY.replace(":documentId", documentId)}?reqId=${reqId}`,
    data
  ),
});

export const rejectEntityRequest = (documentId, entityId) => ({
  type: actions.REJECT_ENTITY,
  payload: axios.delete(
    `${api.DOCUMENT_ENTITY.replace(
      ":documentId",
      documentId
    )}/${entityId}?reqId=${entityId}`
  ),
});

export const rejectEntityNoInterestRequest = (documentId, entityId) => ({
  type: actions.REJECT_ENTITY_NO_INTEREST,
  payload: { documentId, entityId },
});

export const rejectEntityNotCorrectRequest = (documentId, entityId) => ({
  type: actions.REJECT_ENTITY_NOT_CORRECT,
  payload: { documentId, entityId },
});

export const acceptEntityRequest = (documentId, entityId) => ({
  type: actions.ACCEPT_ENTITY,
  payload: axios.put(
    `${api.DOCUMENT_ENTITY.replace(
      ":documentId",
      documentId
    )}/${entityId}?reqId=${entityId}`,
    {
      approve: true,
    }
  ),
});

export const rejectBulkEntitiesRequest = (documentId, entity_ids) => ({
  type: actions.REJECT_BULK_ENTITIES,
  payload: axios.put(
    api.BULK_ENTITIES_STATUS.replace(":documentId", documentId),
    { entity_ids, type: "reject" }
  ),
});

export const acceptBulkEntitiesRequest = (documentId, entity_ids) => ({
  type: actions.ACCEPT_BULK_ENTITIES,
  payload: axios.put(
    api.BULK_ENTITIES_STATUS.replace(":documentId", documentId),
    { entity_ids, type: "accept" }
  ),
});

export const deleteRelationshipRequest = (documentId, relationshipId) => ({
  type: actions.DELETE_RELATIONSHIP,
  payload: axios.delete(
    `${api.ENTITY_RELATIONSHIP.replace(
      ":documentId",
      documentId
    )}/${relationshipId}`
  ),
});

export const removeManyRelationshipsRequest = (
  documentId,
  relationshipIds,
  reqId
) => ({
  type: actions.DELETE_RELATIONSHIP,
  payload: axios.post(
    `${api.REMOVE_MANY_RELATIONSHIPS.replace(
      ":documentId",
      documentId
    )}?reqId=${reqId}`,
    { ids: relationshipIds }
  ),
});

export const addRelationshipRequest = (documentId, data) => {
  const reqId = `${data.source_id},${data.target_id}`;
  return {
    type: actions.ADD_RELATIONSHIP,
    payload: axios.post(
      `${api.ENTITY_RELATIONSHIP.replace(
        ":documentId",
        documentId
      )}?reqId=${reqId}`,
      data
    ),
  };
};

export const changeSingleEntityRequest = (documentId, data) => ({
  type: actions.CHANGE_SINGLE_ENTITY,
  payload: axios.put(
    `${api.BULK_ENTITIES.replace(
      ":documentId",
      documentId
    )}?reqId=${data.ids.join(",")}`,
    data
  ),
});

export const changeMultipleEntitiesRequest = (documentId, data) => ({
  type: actions.CHANGE_MULTIPLE_ENTITIES,
  payload: axios.put(
    `${api.BULK_ENTITIES.replace(
      ":documentId",
      documentId
    )}?reqId=${data.ids.join(",")}`,
    data
  ),
});

export const getAnalysisDetails = (documentId, reqId) => {
  if (cancelTokenSource) {
    cancelTokenSource.cancel("Request canceled due to new document ID.");
  }

  cancelTokenSource = axios.CancelToken.source();

  const cf = storage.get(storageIds.CONFIDENCE_FILTER, false);
  let queryStr = `confidence_filters=${cf ? cf.join(",") : "none"}`;
  queryStr += reqId ? `&reqId=${reqId}` : "";

  return {
    type: actions.ANALYSIS_DETAILS,
    payload: axios
      .get(
        `${api.DOCUMENT_ANALYSIS.replace(
          ":documentId",
          documentId
        )}?${queryStr}`,
        {
          cancelToken: cancelTokenSource.token,
        }
      )
      .catch(function (thrown) {
        if (axios.isCancel(thrown)) {
          console.log("Request canceled:", thrown.message);
        } else {
          console.error("Error occurred:", thrown);
        }
      }),
  };
};

export const getDocumentTLP = (documentId) => {
  return {
    type: actions.GET_DOCUMENT_TLP,
    payload: axios.get(api.DOCUMENT_TLP.replace(":documentId", documentId)),
  };
};

export const addDocumentTLPRequest = (documentId, tlp) => {
  return {
    type: actions.ADD_DOCUMENT_TLP,
    payload: axios.post(
      api.DOCUMENT_TLP.replace(":documentId", documentId),
      tlp
    ),
  };
};

export const deleteEntityGroupTLP = (documentId, groupName) => {
  return {
    type: actions.DELETE_DOCUMENT_TLP,
    payload: axios.delete(
      `${api.DOCUMENT_TLP.replace(":documentId", documentId)}/${groupName}`
    ),
  };
};
