import axios from "axios";
import api from "../../constants/api";

const actions = {
  SET_SEARCH_STATE: "SET_SEARCH_STATE",
  RESET_SEARCH_STATE: "RESET_SEARCH_STATE",
  GET_SEARCH_REQUEST: "GET_SEARCH_REQUEST",
};

export default actions;

/* Action creators */

export const setDocumentSearch = (data) => ({
  type: actions.SET_SEARCH_STATE,
  payload: data?.documents,
});

export const resetSearchState = () => ({
  type: actions.RESET_SEARCH_STATE,
});

export const getSearchRequest = (queryString = "") => ({
  type: actions.GET_SEARCH_REQUEST,
  payload: axios.get(`${api.DOCUMENT_SEARCH}?${queryString}`),
});
