const env = window._env_;
const UNIFIED_URL = env ? env.UNIFIED_SERVER_URL : "";

export const contentType = {
  FORM_DATA: "multipart/form-data",
  JSON: "application/json",
};

export const methods = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
};

const DOCUMENTS = "/documents";
const UPLOADS = UNIFIED_URL + "/input";
// const UPLOADS = "/uploads";
const ORGANISATIONS = "/organisations";
const USERS = "/users";
const SOURCE = "/source";
const COLLECTIONS = UNIFIED_URL + "/organisation/collections";
const MANUAL_RULES = UNIFIED_URL + "/manual_rules";

const api = {
  contentType,
  methods,
  DOCUMENTS,
  DOCUMENT_API: UNIFIED_URL + `${DOCUMENTS}`,
  DOCUMENT_ANALYSIS: UNIFIED_URL + `${DOCUMENTS}/:documentId/aitl`,
  DOCUMENT_ENTITY: UNIFIED_URL + `${DOCUMENTS}/:documentId/entity`,
  DOCUMENT_ENTITIES: UNIFIED_URL + `${DOCUMENTS}/:documentId/entities`,
  DOCUMENTS_COUNTS_ORG: UNIFIED_URL + `${DOCUMENTS}/count/organisation`,
  DOCUMENTS_COUNTS_USER: UNIFIED_URL + `${DOCUMENTS}/count/user`,
  GENERATE_STIX_BUNDLE: UNIFIED_URL + `${DOCUMENTS}/:documentId/generate_stix`,
  DOCUMENT_STIX_BUNDLE: UNIFIED_URL + `${DOCUMENTS}/:documentId/stix_bundle`,
  DOCUMENT_EXTENDED_STIX:
    UNIFIED_URL + `${DOCUMENTS}/:documentId/extended_stix`,
  DOCUMENT_PERFORMANCE_OVERVIEW:
    UNIFIED_URL + `${DOCUMENTS}/:documentId/performance_overview`,
  DOCUMENT_SEARCH: UNIFIED_URL + `${DOCUMENTS}/search`,
  BULK_ENTITIES: UNIFIED_URL + `${DOCUMENTS}/:documentId/entities`,
  BULK_ENTITIES_STATUS:
    UNIFIED_URL + `${DOCUMENTS}/:documentId/entities_status`,
  REMOVE_MANY_RELATIONSHIPS:
    UNIFIED_URL + `${DOCUMENTS}/:documentId/remove_relationships`,
  ENTITY_RELATIONSHIP: UNIFIED_URL + `${DOCUMENTS}/:documentId/relationship`,
  UPDLOAD_FILE: `${UPLOADS}/file`,
  UPLOAD_TEXT: `${UPLOADS}/text`,
  UPLOAD_URL: `${UPLOADS}/url`,
  ORGANISATIONS,
  ORGANISATIONS_ENDPOINTS: UNIFIED_URL + `${ORGANISATIONS}/endpoints`,
  ORGANISATIONS_MISP: UNIFIED_URL + `${ORGANISATIONS}/endpoints/misp`,
  COLLECTIONS: COLLECTIONS,
  COLLECTION_DOCUMENTS: `${COLLECTIONS}/:collId/documents/`,
  COLLECTION_BUNDLE: `${COLLECTIONS}/:collId/bundle/`,
  METADATA_KEYS: UNIFIED_URL + "/organisation/metadata/keys",
  METADATA_PROFILES: UNIFIED_URL + "/organisation/metadata/profiles/",
  DOCUMENT_METADATA_KEYS: UNIFIED_URL + "/documents/:documentId/metadata/keys",
  DOCUMENT_METADATA_PROFILES:
    UNIFIED_URL + "/documents/:documentId/metadata/profiles",
  API_KEY: `${USERS}/api_key`,
  DOCUMENT_COLLECTIONS: UNIFIED_URL + "/documents/:documentId/collections/",
  DOCUMENT_TLP: UNIFIED_URL + "/documents/:documentId/tlp",
  EXTRACTION_PERFORMANCE: UNIFIED_URL + "/extraction_performance",
  SOURCE,
  MANUAL_RULES,
};

export default api;
